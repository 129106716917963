import { useState, useEffect } from 'react';

const useUserProfile = () => {
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [agentProfilePic, setAgentProfilePic] = useState(null);
  const [loading, setLoading] = useState(true);

    const fetchUserProfile = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_CHATAGENT_URI}/user/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }
        const data = await response.json();
        setUserProfilePic(data.profile_pic != null ? `data:image/jpeg;base64,${data.profile_pic}` : '/user-profile-photo.png');

      // Set AI profile picture based on involvement
      if (data.involvement.includes('involved')) {
        setAgentProfilePic('/involved.png');
      } else if (data.involvement.includes('affected')) {
        setAgentProfilePic('/affected.png');
      } else if (data.involvement.includes('managing')) {
        setAgentProfilePic('/managing.png');
      } else {
        setAgentProfilePic('/involved.png'); // Set a default image
      }

      } catch (err) {
        console.error("Error fetching user profile", err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
        fetchUserProfile();
      
      }, []);

  return { userProfilePic, setUserProfilePic, agentProfilePic, setAgentProfilePic, loading };
};

export default useUserProfile;
