// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-page {
    background: 
    linear-gradient(-105deg, #FB8C9E, #8b3d46, #612121 100.00%),
    linear-gradient(to bottom, #852437, #1F080D);
        height: 100vh;
        align-items: center;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
}
.terms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    
}

.terms-content {
    padding: 50px;
    max-width: 40%;
}


@media (max-width: 1024px) {
    .terms-content {
        max-width: 60%;
    }
}

@media (max-width: 768px) {
    .terms-content {
        max-width: 80%;
    }
}

@media (max-width: 480px) {
    .terms-content {
        max-width: 90%;
    }
}

.terms-title {
    margin-bottom: 8px;
    font-size: 36px;
    font-weight: 500;
    color: white;
    text-wrap: nowrap;

    background: linear-gradient(
      90deg,
      #DE2B4F 0%,
      #FFD1D1 104.43%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.terms-paragraph{
    color: white;
    display: block;
    text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA;IACI;;gDAE4C;QACxC,aAAa;QACb,mBAAmB;QACnB,YAAY;QACZ,gBAAgB;QAChB,kBAAkB;AAC1B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;;AAGA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;;IAEjB;;;;KAIC;IACD,qBAAqB;IACrB,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".terms-page {\n    background: \n    linear-gradient(-105deg, #FB8C9E, #8b3d46, #612121 100.00%),\n    linear-gradient(to bottom, #852437, #1F080D);\n        height: 100vh;\n        align-items: center;\n        flex-grow: 1;\n        overflow-y: auto;\n        overflow-x: hidden;\n}\n.terms-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: start;\n    \n}\n\n.terms-content {\n    padding: 50px;\n    max-width: 40%;\n}\n\n\n@media (max-width: 1024px) {\n    .terms-content {\n        max-width: 60%;\n    }\n}\n\n@media (max-width: 768px) {\n    .terms-content {\n        max-width: 80%;\n    }\n}\n\n@media (max-width: 480px) {\n    .terms-content {\n        max-width: 90%;\n    }\n}\n\n.terms-title {\n    margin-bottom: 8px;\n    font-size: 36px;\n    font-weight: 500;\n    color: white;\n    text-wrap: nowrap;\n\n    background: linear-gradient(\n      90deg,\n      #DE2B4F 0%,\n      #FFD1D1 104.43%\n    );\n    background-clip: text;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n.terms-paragraph{\n    color: white;\n    display: block;\n    text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
