import React, { useState, useContext, useEffect } from 'react';
import { ChatContext } from '../../../Context/ChatContext';
import { FileContext } from '../../../Context/FileContext';
import useHandleSendMessage from '../../../Hooks/useHandleSendMessage';
import axios from 'axios';
import './SuggestedQuestions.css';

const SuggestedQuestions = () => {
    const { allMessages, setAllMessages, messageInput, setMessageInput, error, setError, isLoading, setIsLoading, surveyData , setSurveyData, showFeedback, setShowFeedback} = useContext(ChatContext);
    const { file, setFile, filePreview, setFilePreview, fileError, showUploadPrompt, setShowUploadPrompt, handleUploadClick, handleFileChange}  = useContext(FileContext);
    const [questionPlaceholder, setQuestionPlaceholder] = useState("Suggested Questions");
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [suggestedQuestions, setSuggestedQuestions] = useState([
        { value: "As an individual, should I be worried about starting a relationship with a coworker?", label: "As an individual, should I be worried about starting a relationship with a coworker?" },
        { value: "As an HR leader, how should I address any potential conflicts of interest or favoritism?", label: "As an HR leader, how should I address any potential conflicts of interest or favoritism?" },
        { value: "As a manager, what are the best practices for creating policies regarding romantic relationships among employees?", label: "As a manager, what are the best practices for creating policies regarding romantic relationships among employees?" }
    ]);

    useEffect(() => {
        const fetchSuggestedQuestions = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/suggested-questions`,
                    { allMessages: allMessages });
                setSuggestedQuestions(response.data);
            } catch (error) {
                console.error('Error fetching suggested questions:', error);
            }
        };

        if (allMessages.length > 0) {
            fetchSuggestedQuestions();
        }
       
    }, [allMessages]);

    useEffect(() => {
        // Clear selected question when suggested questions change
        if (allMessages.length > 0) {
            setSelectedQuestion(null);
        }

    }, [suggestedQuestions]);

    const { handleSendMessage } = useHandleSendMessage({
        allMessages, setAllMessages,
        messageInput,setMessageInput,
        filePreview, setFilePreview,
        error, setError,
        file, setFile,
        fileError,
        showUploadPrompt, setShowUploadPrompt,
        showFeedback, setShowFeedback,
        isLoading, setIsLoading,
        surveyData
      });

    const handleSuggestedQuestionSelect = (e, selectedOption) => {
        setSelectedQuestion(selectedOption);
        const selectedQuestion = selectedOption ? selectedOption.value : '';
        setMessageInput(selectedQuestion);

        handleSendMessage(e, selectedQuestion);
    };

    return (
        <div className="suggested-questions-container">
            {suggestedQuestions && !isLoading && !error && allMessages.length != 0 && (
                suggestedQuestions.map((question, index) => (
                    <button
                        key={index}
                        className="suggested-question-button"
                        onClick={(e) => handleSuggestedQuestionSelect(e, question)}
                    >
                        {question.label} 
                    </button>
                ))
            )}
        </div>
    );
};

export default SuggestedQuestions;
