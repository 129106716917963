import React, { useState, useContext, useEffect, useRef  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrivacyNotice from '../PrivacyNotice/PrivacyNotice';
import './Questions.css';
import { ChatContext } from '../../../Context/ChatContext';
import { FileContext } from '../../../Context/FileContext';
import useHandleSendMessage from '../../../Hooks/useHandleSendMessage';

function Questions() {

    const [showNotice, setShowNotice] = useState(false);
    const navigate = useNavigate();
    const { allMessages, setAllMessages, messageInput, setMessageInput, error, setError, isLoading, setIsLoading } = useContext(ChatContext);
    const [questionInput, setQuestionInput] = useState(null);
    const { file, setFile, filePreview, setFilePreview, fileError, setFileError, handleUploadClick, handleRemoveFile, showUploadPrompt, setShowUploadPrompt, handleFileChange}  = useContext(FileContext);
    const [isRecordingMic, setIsRecordingMic] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const textareaRef = useRef(null);

    const { handleSendMessage } = useHandleSendMessage({
        allMessages, setAllMessages,
        messageInput,setMessageInput,
        filePreview, setFilePreview,
        error, setError,
        file, setFile,
        fileError,
        showUploadPrompt, setShowUploadPrompt,
        isLoading, setIsLoading,
      });

      const handleQuestionClick = (e, questionSelected) => {
        e.preventDefault();
        setMessageInput(questionSelected);

        if (localStorage.getItem('privacyAgreed')) {
            navigate('/chatbot');
            if (questionSelected) handleSendMessage(e, questionSelected);
        } else {
            setShowNotice(true);
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (localStorage.getItem('privacyAgreed')) {
            if (questionInput) handleSendMessage(e, questionInput);
            navigate('/chatbot');
        } else {
            setShowNotice(true);
        }
    }

    const handleNoticeClose = () => {
        setShowNotice(false);
    };

    const handleNoticeProceed = () => {
        navigate('/chatbot');
    };


    const handlePlusClick = (e) => {
        e.preventDefault();
    };


    const handleMicClick = (e) => { 
        e.preventDefault();

        if (!isRecordingMic) {
            try {
                const recognition = new window.webkitSpeechRecognition(); 
                recognition.lang = 'en-US'; 
                recognition.start(); 
    
                recognition.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setMessageInput(transcript); 
                };
    
                recognition.onerror = (event) => {
                    console.error('Speech recognition error:', event.error);
                };

                recognition.onend = () => {
                    setIsRecordingMic(false);
                };

                setRecognition(recognition);
                setIsRecordingMic(true);

            } catch (error) {
                console.error('Error initializing speech recognition:', error);
            }
        } else {
            if (recognition) {
                recognition.stop();
                setIsRecordingMic(false);
            }
        }
    }
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setShowNotice(true);
        }
    }

    const adjustTextareaHeight = (e) => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = '10px'; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
        }
    };    

    useEffect(() => {
        adjustTextareaHeight(); // Adjust height whenever messageInput changes
    }, []);

    
    useEffect(() => {
        adjustTextareaHeight(); // Adjust height whenever messageInput changes
    }, [messageInput]);

        // Add event listener to handle window resize
        useEffect(() => {
            const handleResize = () => {
                adjustTextareaHeight();
            };
    
            window.addEventListener('resize', handleResize);
    
            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

    return (
        <div className="questions">
            <div className="question-bar-container">
                    <form className="questionbar" onKeyPress={handleKeyPress}>
                        <button className="plus-button">
                                <img src="/plus.png" className="plus-image" onClick={handleUploadClick}/>
                        </button>
                
                        <div className="questionbar-inputs">
                        <div className="file-container">
                        <input  id="file-input" type="file"  onChange={(e) => handleFileChange(e)}/>
                            {filePreview && 
                                <div className="file-preview-container">
                                        <div className="file-container">
                                            <img src="/file-icon-p.png" className="file-icon"/>
                                            <p className="file-name-w">{filePreview}</p>
                                        </div>
                                            
                                    
                                    <div className="remove-file">
                                        <button className="remove-button" onClick={handleRemoveFile}>X</button>
                                    </div>
                                </div>            
                                }
                                </div>

                            <textarea
                                ref={textareaRef}
                                className="qinput"
                                placeholder="Share your thoughts..."
                                value={messageInput}
                                onChange={(e) => { 
                                    setMessageInput(e.target.value);
                                    setQuestionInput(e.target.value);
                                    adjustTextareaHeight();
                                    }}
                                rows="1" 
                            />
                        </div>

                        <button className="mic-button">
                            <img src={isRecordingMic ? "/mic-rec.png" : "/microphone.png"} className="mic-image" onClick={handleMicClick}/>
                        </button>

                        <button type="submit" className="submit2-button" onClick={(e)=>handleSubmit(e, questionInput)}>
                            <img src="/send.png" alt="" className="submit-image" />
                        </button>
                    </form>
                </div>

            <PrivacyNotice showNotice={showNotice} handleClose={handleNoticeClose} handleProceed={handleNoticeProceed} />

                <div className="questions-container">
                        <div className="question-and-caption">
                            <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'How can I maintain professionalism while navigating a romantic relationship with a coworker?')}>
                                <p className='q'>
                                    How can I maintain professionalism while navigating a romantic relationship with a coworker?
                                </p>
                                <span className="arrow">➜</span>
                            </Link>

                            <div className="caption">I am involved</div>
                        </div>

                        <div className="question-and-caption">
                            <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'How should I address any potential conflicts of interest or favoritism among my coworkers in a romantic relationship?')}>
                                <p className='q'>
                                How should I address any potential conflicts of interest or favoritism among my coworkers in a romantic relationship?
                                </p>
                                <span className="arrow">➜</span>
                            </Link> 

                            <div className="caption"> I am affected</div>
                        </div>

                        <div className="question-and-caption">
                            <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'What are best practices for creating workplace policies regarding romantic relationships among employees?')}>
                                <p className='q'>
                                What are best practices for creating workplace policies regarding romantic relationships among employees?
                                </p>
                                <span className="arrow">➜</span>
                            </Link>

                            <div className="caption">I am an HR leader</div>
                        </div>

                </div>              
        </div>
    );
}

export default Questions;

