import "./Login.css";
import NavBar from '../../Components/NavBar/NavBar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); 
    const { userLogin, isLoggedIn } = useContext(AuthContext);
    const [error, setError] = useState('');
    const location = useLocation();
    const socialError = location.state?.socialError;
    const resetSuccess = location.state?.resetSuccess;

    useEffect(() => {
        if (isLoggedIn) {
          navigate('/chatbot');
        }
    }, [isLoggedIn]);

    const handleLinkedinLogin = async (event) => {
        event.preventDefault();

        const redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI;
        const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_LINKEDIN_CLIENT_SECRET;
        const scope = "email%20profile%20openid";  
        const state = "linkedin";
    
        // Generate linkedin auth url and open window
        const linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
        window.location.href = linkedInUrl;
    };

    const handleGoogleLogin = async (provider, data) => {
        try {
            const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
            const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
            const scope = "email%20profile%20openid";  

            const googleUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`
            window.location.href = googleUrl;
    
        } 
        
        catch (error) {
            console.error('Error:', error);
        }
    };

    // Send login data to server
    const handleLogin = async (event) => {
        event.preventDefault();

        // Clear other error or success messages
        if (socialError || resetSuccess) {
            navigate(location.pathname, { replace: true, state: null });
        }
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/login`, {email, password});
            console.log('Response from backend:', response.data);

            if (response.status === 200) {
                // User successfully logged in, redirect to chatbot;
                userLogin(response.data['survey_data'], response.data['access_token'], response.data['first_name']);
                navigate('/chatbot');
                console.log("Log in successful");
            } else if (response.status === 400) {
                setError("Server error. Please try again. ");
                console.error('Login failed');
            }
        } catch (error) {
            setError("Please try again or reset your password.");
        }
    }

    return (
        <div className="login-page">
            <NavBar />
            <main className="login-container">
                <h1 className="login-title">Log in to your account</h1>

               

                <form onSubmit={handleLogin}>
                    <div className='email-field'>
                        <label htmlFor="email" />
                        <input type="email" 
                        id="email" 
                        required
                        autoComplete="email"
                        className="input-field"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        placeholder="Enter Email" />
                    </div>
    
                    <div className="password-field">
                        <label htmlFor="password" className="password-label"/>
                        <input type="password" 
                        id="password" 
                        required
                        className="input-field" 
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        placeholder="Enter Password"/> 
                    </div>

                    
                    {error && <p className="error">{error}</p>}
                    {socialError && <p className="error">{socialError}</p>}
                    {resetSuccess && <p className="success">{resetSuccess}</p>}


                    <div className="login-button-container">
                        <button type="submit" className="login-button">LOG IN</button>
                    </div>
                </form>

                <div className="separator">
                    <hr className="line" />
                    <span>OR</span>
                    <hr className="line" />
                </div>
                    <button className="google-login" onClick={handleGoogleLogin}>
                        <img src="/google-icon.png" alt="" className="google-icon" />
                        <span className="continue-text">Continue with Google</span>
                    </button>

                    <button className="linkedin-login" onClick={handleLinkedinLogin}> 
                        <img src="/linkedin.png" alt="" className="linkedin-icon" />
                        <span className="continue-text">Continue with LinkedIn</span>
                    </button>

                <p className="signup-link">
                    Don't have an account?{" "}
                    <a href="/signup" className="link-to-signup">Sign up</a>
                </p>
                <p className="forgot-link">
                    <a href="/forgot-password" className="link-to-signup">Forgot password?</a>
                </p>
            </main>
        </div>
    );
}

export default Login;


