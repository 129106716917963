import React, { useEffect, useState } from 'react';
import './PrivacyNotice.css';

function PrivacyNotice({ showNotice, handleClose, handleProceed }) {

    const [isAgreed, setIsAgreed] = useState(false);
    
    // Check if the user has already agreed when the component mounts
    useEffect(() => {
        const agreed = localStorage.getItem('privacyAgreed');
        if (agreed === 'true') {
            setIsAgreed(true);
            handleClose();  // Close the notice if user has already agreed
        }
    }, [handleClose]);

    const handleAgreementChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            localStorage.setItem('privacyAgreed', 'true');  // Save agreement in localStorage
            setIsAgreed(true);
            handleProceed();  // Proceed with the chat or any other action
        }
    };

    return (
        <>
            {showNotice && (
                <div className="notice">
                    <button className="close-button" onClick={handleClose}>x</button>
                    <div className="notice-content">
                            <h1 className="notice-title">
                                <strong>Privacy Notice</strong>
                            </h1>
                            <p className="notice-content">
                                Any information you provide on this website, including text, queries, or personal details,
                                will be treated with the utmost confidentiality. We prioritize the security and privacy of 
                                your data. By checking the box below, you agree to the&nbsp;
                                <a href="/privacy-policy" className="terms-and-conditions">Privacy Policy</a> &&nbsp;
                                <a href="/terms-and-conditions" className="terms-and-conditions">Terms and Conditions</a> 
                             .
                            </p>

                         <div className='checkbox-container'>
                            <input type="checkbox" className="proceed-checkbox" onChange={handleAgreementChange} />
                            <span className="checkbox-label"> I agree</span> <span className="required">*</span>
                        </div>   

                    </div>
                </div>
            )}
        </>
    );
}

export default PrivacyNotice;
