// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home {
    /* background: linear-gradient(to bottom, #08011A, #35052C, #08011A);  */
    background: linear-gradient(to bottom, black, #0F0101, #101C20);    
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,wEAAwE;IACxE,+DAA+D;AACnE","sourcesContent":[".Home {\n    /* background: linear-gradient(to bottom, #08011A, #35052C, #08011A);  */\n    background: linear-gradient(to bottom, black, #0F0101, #101C20);    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
