// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggested-questions-container {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    gap: 10px; /* Space between buttons */
    flex-wrap:nowrap; 
}


.suggested-question-button {
    background-color: #f0f0f0;
    padding: 18px 15px;
    cursor: pointer;
    border-radius: 15px;
    border: 4px solid #972234f3; 
    /* border: 3px solid #ffffff72;  */
    border: 4px solid #97223400; 
    background-image: linear-gradient(to right, #e15f72bb, #972234cb); 
    background: linear-gradient(to right, #e15f72, #972234);  
    background-origin: border-box; 
    color: #ffffff; 
    font-size: 18px; 
    
    max-width: 40%;  
    text-align: center; 

 
}

.suggested-question-button:hover {
    background: rgb(229, 183, 183);
    background: linear-gradient(to right, #e15f72, #972234);  
}

.suggested-question-button:focus {
    outline: none;
    /* border-color:#be5262;  */
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatbotComponents/SuggestedQuestions/SuggestedQuestions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS,EAAE,0BAA0B;IACrC,gBAAgB;AACpB;;;AAGA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,2BAA2B;IAC3B,kCAAkC;IAClC,2BAA2B;IAC3B,iEAAiE;IACjE,uDAAuD;IACvD,6BAA6B;IAC7B,cAAc;IACd,eAAe;;IAEf,cAAc;IACd,kBAAkB;;;AAGtB;;AAEA;IACI,8BAA8B;IAC9B,uDAAuD;AAC3D;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B","sourcesContent":[".suggested-questions-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center; \n    align-items: center;\n    gap: 10px; /* Space between buttons */\n    flex-wrap:nowrap; \n}\n\n\n.suggested-question-button {\n    background-color: #f0f0f0;\n    padding: 18px 15px;\n    cursor: pointer;\n    border-radius: 15px;\n    border: 4px solid #972234f3; \n    /* border: 3px solid #ffffff72;  */\n    border: 4px solid #97223400; \n    background-image: linear-gradient(to right, #e15f72bb, #972234cb); \n    background: linear-gradient(to right, #e15f72, #972234);  \n    background-origin: border-box; \n    color: #ffffff; \n    font-size: 18px; \n    \n    max-width: 40%;  \n    text-align: center; \n\n \n}\n\n.suggested-question-button:hover {\n    background: rgb(229, 183, 183);\n    background: linear-gradient(to right, #e15f72, #972234);  \n}\n\n.suggested-question-button:focus {\n    outline: none;\n    /* border-color:#be5262;  */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
