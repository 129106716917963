import React from "react";
import './Features.css';

function Features() {
  const Features = [
    {
      title: "We Prioritize Your Privacy First",
      description:
        "All conversations and questions are kept confidential, so that you can receive the highest level of quality and feedback.",
      image: "/feature1.png",
      imageAlt: "Illustration representing privacy and confidentiality",
      reversed: false,
    },
    {
      title: "A Fresh Perspective",
      description:
        "Romance At Work offers a distinct, tailored that allows you to comfortably  ask questions that may feel daunting.",
      image: "/feature2.png",
      imageAlt: "Illustration representing a fresh perspective",
      reversed: true,
    },
    {
      title: "Beyond A Chat",
      description:
        "Romance At Work goes above and beyond question-and-feedback. Equipped with an insights summary,  your conversations take on a whole new level.",
      image: "feature3.png",
      imageAlt: "Illustration representing going beyond a chat",
      reversed: false,
    },
  ];

  return (
    <div className="features">
      <section className="features-section">
        <h2 className="features-title">How We Can Help</h2>
        {Features.map((section, index) => (
          <FeatureItem key={index} {...section} />
        ))}
      </section>
    </div>
  );
}

function FeatureItem({
  title,
  description,
  image,
  imageAlt,
  reversed,
}) {
  return (
    <article className={`feature-item ${reversed ? "reversed" : ""}`}>
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
      <div className="feature-image-wrapper">
        <img src={image} alt={imageAlt} className="feature-image" />
      </div>
    </article>
  );
}

export default Features;
