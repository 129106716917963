import './Signup.css';
import React from 'react';
import axios from "axios";
import NavBar from '../../Components/NavBar/NavBar';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../Context/AuthContext';

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [industry, setIndustry] = useState('');
  const [involvement, setInvolvement] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { userLogin, isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/chatbot');
    }
}, [isLoggedIn]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(involvement);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/signup`, {firstName, lastName, email, password, role, industry, involvement});
      console.log('Response from backend:', response.data);

      if (response.status === 201) {
          // User successfully signed up
          console.log("Signed up");
          navigate('/login');

      } else {
          console.error('Signup failed');
      }
  } catch (error) {
      console.error('Error signing up:', error);
      setError("Email already exists. Please log in or use another email.");
  }

  };

  
  const roles = [
    { id: 1, name: "Individual Contributor" },
    { id: 2, name:  "Manager"},
    { id: 3, name: "Human Resources" }, 
    { id: 4, name: "Other" }
  ];

  const industries = [
    { id: 1, name: "Agriculture and Food Production" },
    { id: 2, name: "Consumer Goods and Retail" },
    { id: 3, name: "Education and Training" },
    { id: 4, name: "Energy and Utilities" },
    { id: 5, name: "Finance, Insurance, and Real-Estate" },
    { id: 6, name: "Healthcare and Pharmaceuticals" },
    { id: 7, name: "Manufacturing and Construction" }, 
    { id: 8, name: "Military and Defense" },
    { id: 9, name: "Technology and Telecommunications"},
    { id: 10, name: "Other" }
  ];

  const involvements = [
    { id: 1, name: "I am or want to be involved in a romantic relationship at work." },
    { id: 2, name: "I am affected by coworkers in a romantic relationship at work." },
    { id: 3, name: "I am managing or creating policies for romantic relationships at work." },
    { id: 4, name: "I am not involved in or affected by romance at work but I am interested in learning about it." }
  ]; 
  
    return (
      <div className="signup-page">
      <NavBar/>
      <main className="signup-container">
          <div className="signup-form-wrapper">
            <header className="signup-header">
              <h1 className="signup-title">Create An Account</h1>
             
            </header>
            <form onSubmit={handleSubmit}>

            {/* Row 1 - First / Last name */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="firstName" className="signup-form-label">
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="signup-form-input"
                    required
                    autoComplete="given-name"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                  }}
                  />
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="lastName" className="signup-form-label">
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="signup-form-input"
                    required
                    autoComplete="family-name"
                    onChange={(event) => {
                      setLastName(event.target.value);
                  }}
                  />
                </div>
              </div>
            </div>

            {/* Row 2 - Email / Password */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="email" className="signup-form-label">
                    Email Address <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="signup-form-input"
                    required
                    autoComplete="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                  }}
                  />
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="password" className="signup-form-label">
                    Password <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="signup-form-input"
                    required
                    onChange={(event) => {
                      setPassword(event.target.value);
                  }}
                  />
                </div>
              </div>
            </div>

            {/* Row 3 - Role / Industry */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="role" className="signup-form-label">
                    What is your role? <span className="required">*</span>
                  </label>
                  <div className="signup-form-select">
                    <select 
                    id="role" 
                    className="select-input" 
                    required
                    onChange={(event) => {
                      const selectedRole = roles.find(role => role.id === parseInt(event.target.value));
                      setRole(selectedRole ? selectedRole.name : '');
                    }}

                    >
                      <option value=""></option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="industry" className="signup-form-label">
                    What is your industry? <span className="required">*</span>
                  </label>
                  <div className="signup-form-select">
                    <select 
                    id="industry" 
                    className="select-input" 
                    required
                    onChange={(event) => {
                      const selectedIndustry = industries.find(industry => industry.id === parseInt(event.target.value));
                      setIndustry(selectedIndustry ? selectedIndustry.name : '');
                    }}
                    >
                      <option value=""></option>
                      {industries.map((industry) => (
                        <option key={industry.id} value={industry.id}>
                          {industry.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              
            </div>

            <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="industry" className="signup-form-label">
                    What is your involvement with romance at work? <span className="required">*</span>
                  </label>
                  <div className="signup-form-select">
                    <select 
                    id="involvement" 
                    className="select-input" 
                    required
                    onChange={(event) => {
                      const selectedInvolvement = involvements.find(involvement => involvement.id === parseInt(event.target.value));
                      setInvolvement(selectedInvolvement ? selectedInvolvement.name : '');
                    }}
                    >
                      <option value=""></option>
                      {involvements.map((involvement) => (
                        <option key={involvement.id} value={involvement.id}>
                          {involvement.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

            {error && <p className="error">{error}</p>}
            <div className='submit-button'>
            <button type="submit" className="signup-form-submit">
              SIGN UP
            </button>
            </div>
            </form> 
            
            <div className="signup-form-login">
              <span>Already have an account?</span>{" "}
              <a href="/login" className="signup-form-login-link">
                Log In
              </a>
            </div>
          </div>
        </main>
      </div>
        
  );
}

export default Signup;