import React, { useContext, useState, useRef, useEffect } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import useUserProfile from '../../Hooks/useUserProfile';

function NavBar() {
    const { isLoggedIn, userLogout } = useContext(AuthContext);
    const { userProfilePic, userFirstName } = useUserProfile();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleProfileClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener on component unmount or dropdown close
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    return (
        <div className="nav-bar">
            <div className="logo-container">
                <Link to="/" className="logo">Romance At Work</Link>
            </div>
            {isLoggedIn ? (
                <nav className="nav-buttons">
                    <div className="user-photo-container" onClick={handleProfileClick}>
                    {localStorage.getItem('firstName') && (<div className="user-name">{localStorage.getItem('firstName')}</div> )}
                        {userProfilePic ? (
                            <img src={userProfilePic} alt="Profile" className="user-photo" />
                        ) : (
                            <img src="/user-profile-photo.png" alt="Profile" className="user-photo" />
                        )}
                    </div>
                    {showDropdown && (
                        <div className="dropdown-menu" ref={dropdownRef}>
                            <Link to="/profile" className="dropdown-item" onClick={() => setShowDropdown(false)}>
                                Profile
                            </Link>
                            <div className="dropdown-item" onClick={() => { userLogout(); setShowDropdown(false); }}>
                                Log out
                            </div>
                        </div>
                    )}
                </nav> 
            ) : (
                <nav className="nav-buttons">
                    <Link to="/login" className="log-in">Log in</Link>
                    <Link to="/signup" className="sign-up">Sign up</Link> 
                </nav>
            )}
        </div>
    );
}

export default NavBar;