import React from 'react';
import './Statistics.css'

const Statistics = () => {
    return (
        <div className="statistics-content">
            <h3 className="statistics-title">Why You May Need Support Navigating Workplace Romance</h3>
            <div className="statistics-intro">
            Workplace romances are more common than you might think. 
            While they can lead to lasting relationships, they also come with potential complications. 
            This is why seeking support is important:
            </div>

            <div className="statistics-box">
                <div className="circle-number">
                    <div className="circle">
                        <div className="number">
                            I
                        </div>
                    </div>
                </div>
                <div className="box-content">
                You are not alone: 30% of employees have dated a coworker. With romance blossoming in offices everywhere, it's important to understand the potential implications and how to navigate these relationships responsibly.  
                </div>
            </div>

            <div className="statistics-box">
                <div className="circle-number">
                        <div className="circle">
                            <div className="number">
                                II
                            </div>
                        </div>
                    </div>

                <div className="box-content">
                Things can get complicated: 40% of workplace romances unfortunately involve infidelity or harassment. Having confidential support ensures you can protect yourself and those around you, while being aware of the ethical and legal boundaries.
                </div>
            </div>

            <div className="statistics-box">
                <div className="circle-number">
                    <div className="circle">
                        <div className="number">
                            III
                        </div>
                    </div>
                </div>
                <div className="box-content">
                Those in the long run: 50% of workplace romances result in marriage or long-term commitment. Support can help you manage the complexities of transitioning from colleagues to partners while maintaining professionalism. 
                </div>
            </div>

            <div className="statistics-box">
                <div className="circle-number">
                    <div className="circle">
                        <div className="number">
                            IV
                        </div>
                    </div>
                </div>
                <div className="box-content">
                Word may get around: 60% of employees report gossip and distraction due to workplace romances. Confidential guidance can help you minimize disruption and protect your reputation, fostering a more productive work environment for everyone.
                </div>
            </div>

            <div className="statistics-source">
                Statistics sourced from Forbes.com
            </div>

            <div className="statistics-intro">
            Need someone to talk to? With Romance at Work, receive confidential and expert advice to help navigate the complexities and nuances of workplace romance.
            </div>

        </div>

    );
};

export default Statistics;