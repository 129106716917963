import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../Context/AuthContext';
import { useContext } from 'react';
import { ChatContext } from '../Context/ChatContext';
import NavBar from '../Components/NavBar/NavBar';

const LinkedInAuth = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { userLogin } = useContext(AuthContext);
    const { surveyData, resetSurveyData } = useContext(ChatContext);
    const processed = useRef(false);

    const handleLinkedInCallback = async (code) => {      
      try {
          // send access code to backend for authentication
          const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/linkedin`, { code });
          console.log('Backend response:', response.data);
          
          userLogin(response.data['survey_data'], response.data['access_token'], response.data['first_name']);
          navigate('/chatbot');

      } catch (error) {
          console.error('Error sending code to backend:', error);
          navigate('/login', { state: { socialError: "Error logging in with LinkedIn. Please try again or login with email." } });
      } 
      };

    useEffect(() => {

      if (processed.current) return; // Process code for access token once to avoid errors
      processed.current = true;

      // Extract the code parameter from the URL
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      
      if (code) {
          handleLinkedInCallback(code); // handle callback, send code to backend for authentication
        
       } else {
          console.error('No code found in URL');
        }

    }, [location.search]);

  return (
    <div className="login-page">
      <NavBar/>
      <p className="processing">Processing...</p>
    </div>
  );
};

export default LinkedInAuth;
