// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EndButton {
    display: flex;
    justify-content: center;
    position: relative;
}

.end-button{
    background: #B53E53;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px; 
    margin-top: 5px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 17px;
    display: flex;
}

@media (max-width: 700px){
    .end-button{
        font-size: 15px;
        padding: 10px 20px; 
     }
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatbotComponents/EndButton/EndButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,0CAA0C;IAC1C,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;AACjB;;AAEA;IACI;QACI,eAAe;QACf,kBAAkB;KACrB;AACL","sourcesContent":[".EndButton {\n    display: flex;\n    justify-content: center;\n    position: relative;\n}\n\n.end-button{\n    background: #B53E53;\n    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);\n    color: #fff;\n    border-radius: 10px;\n    padding: 10px 20px; \n    margin-top: 5px;\n    text-decoration: none;\n    border: none;\n    cursor: pointer;\n    font-size: 17px;\n    display: flex;\n}\n\n@media (max-width: 700px){\n    .end-button{\n        font-size: 15px;\n        padding: 10px 20px; \n     }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
