// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-page {
    background: linear-gradient( #FFEEEE, #EFE5F8);  
    height: 100vh;
    display: flex;
    flex-direction: column;
    
}





`, "",{"version":3,"sources":["webpack://./src/Pages/Chatbot/Chatbot.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,aAAa;IACb,aAAa;IACb,sBAAsB;;AAE1B","sourcesContent":[".chatbot-page {\n    background: linear-gradient( #FFEEEE, #EFE5F8);  \n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    \n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
